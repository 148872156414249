$block : 'input';

.#{$block} {
  font-size: 18px;
  display: block;
  padding: 10px 20px;
  border: 2px solid #efefef;
  border-radius: 4px;
  width: 100%;

  &:focus, &:active, &:hover {
    outline-color: indigo;
  }

  &::placeholder {
    color: grey;
  }

}