.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background: #2d0848;
  padding: 10px;
  line-height: 30px;
  font-size: 20px;
}