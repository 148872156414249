@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Mono:ital,wght@0,400;0,700;1,400&family=Ubuntu:ital,wght@0,300;0,400;0,700;1,300&display=swap');
@import "vars";
@import "avatar";
@import "button";
@import "caret";
@import "checkbox";
@import "container";
@import "extras";
@import "fab";
@import "filter";
@import "form";
@import "header";
@import "icon";
@import "input";
@import "label";
@import "login";
@import "slider";
@import "task";
@import "text-message";
@import "toggle";

body {
  font-size: 16px;
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  // 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  // sans-serif;
  font-family: 'Ubuntu Mono', monospace;
  // font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  background-color: white;
}

* {
  box-sizing: border-box;
  font-family: 'Ubuntu Mono', monospace;
}

code {
  // font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-family: 'Ubuntu Mono', monospace;
}

.appContainer {
  padding: 80px 0 0 0;
}



