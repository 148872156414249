$block : 'btn';

.#{$block} {
  position: relative;
  background: indigo;
  display: inline-block;
  margin: 10px;
  cursor: pointer;
  border: 2px solid indigo;
  color: #fff;
  border-radius: 4px;
  line-height: 30px;
  padding: 0 10px;
  font-weight: bold;
  font-size: 18px;
  transition: background-color 150ms, box-shadow 150ms, transform 150ms;
  text-align: center;
  box-shadow: 0 2px 0 0 #2d0848;
  transform: translate(0,0);

  &:hover {
    background: indigo;
  }

  &--pressed, &:active {
    box-shadow: 0 0 0 0 #ccc;
    transform: translate(0, 2px);
  }

  &--alert {
    background: darkred;
    border: 2px solid darkred;
    box-shadow: 0 2px 0 0 rgb(85, 22, 22);;

    &:hover {
      background: darkred;
    }
  }

  &--white {
    background-color: white;
    color: $color-primary;
    border-color: white;
  }

  &--lime {
    background-color: #9dff75;
    color: $color-primary;
    border-color: #9dff75;
  }

  &--ghost {
    background: transparent;
    color: #9dff75;
    box-shadow: 0 0 0 0 transparent;

    &:hover {
      background: rgba(255,255,255,0.2);
    }

    &:active {
      transform: translate(0,0);
    }
  }

  &--block {
    display: block;
  }

  &--success {
    background-color: forestgreen;
    border-color: forestgreen;

    &:hover {
      background-color: forestgreen;
    }
  }

  &--flat {
    border-color: transparent;
    background: transparent;
    color: indigo;
    padding-left: 0;
    padding-right: 0;
    box-shadow: 0 0 0 0 transparent;

    &:hover {
      border-color: transparent;
      background: transparent;
    }

    &:active {
      transform: translate(0,0);
    }
  }

  &--wide {
    display: block;
    width: 100%;
    margin: 10px 0;
  }
}

