$block : 'task';

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.#{$block} {
  display: flex;
  flex-flow: row nowrap;
  padding: 10px;
  position: relative;
  margin: 10px 0;
  animation: fadein 300ms ease-in-out;

  // &:not(:last-of-type) {
  //   border-bottom: 2px solid lightgray;
  // }

  &__toggle {
    flex: 0 0 30px;
    display: block;
    width: 30px;
    height: 30px;
    border: 2px solid lightgrey;
    margin: 0 20px 0 0 ;
    padding: 0;
    position: relative;
    background: transparent;
    border-radius: 5px;
    transition: border-color 150ms ease-in-out;
    cursor: pointer;
    color: indigo;

    .ico {
      stroke-width: 1;
      opacity: 0;
      stroke-dasharray: 50;
      stroke-dashoffset: 50;
      transition: opacity 50ms ease-in-out,
      stroke-width 250ms ease-in-out,
      stroke-dashoffset 250ms ease-in-out,
      stroke-dasharray 250ms ease-in-out;
    }

    &:hover {
      border-color: indigo;
    }

    &:active {
      border-color: indigo;
      background-color: indigo;
      color: white;

      .ico {
        opacity: 1;
        stroke-width: 2;
        stroke-dasharray: 0;
        stroke-dashoffset: 0;
      }
    }

    &--finished {
      color: white;
      background-color: indigo;
      border-color: indigo;

      .ico {
        opacity: 1;
        stroke-width: 2;
        stroke-dasharray: 0;
        stroke-dashoffset: 0;
      }

      &:active {
        border-color: indigo;
        background-color: indigo;
        color: white;
      }
    }
  }

  &__content {
    flex: 1 1 100%;
  }

  &__head {
    display: flex;
    cursor: pointer;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;

    .caret {
      stroke: transparent;
    }

    &:hover {
      .#{$block}__title {
        color: indigo;
      }

      .caret {
        stroke: #ccc;
      }
    }
  }

  &__title {
    font-size: 20px;
    line-height: 30px;
    padding-right: 50px;
  }

  &__description {
    color: #444;
  
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &__actions {
    display: flex;
    flex-flow: row nowrap;
    align-content: flex-end;
    justify-content: flex-end;
  }

  &__icons {
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;
    font-size: 14px;
    line-height: 20px;
  }

  &:hover {
    // .#{$block}__toggle {
    //   border-color: indigo;
    // }

    // .#{$block}__title {
    //   color: indigo;
    // }
  }
}