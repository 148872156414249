$block: 'ico';

.#{$block} {
  width: 1rem;
  height: 1rem;
  stroke: currentColor;
  vertical-align: text-bottom;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: miter;
  fill: none;
  color: currentColor;

  &--task-toggle {
    width: 28px;
    height: 28px;
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 0;
    margin: 0;
    transform: translate(-50%, -50%);
  }

  &--appear-on-hover {
    stroke-width: 1;
    opacity: 0;
    stroke-dasharray: 50;
    stroke-dashoffset: 50;
    transition: opacity 50ms ease-in-out,
                stroke-width 250ms ease-in-out,
                stroke-dashoffset 250ms ease-in-out,
                stroke-dasharray 250ms ease-in-out;

    &:hover {
      opacity: 1;
      stroke-width: 2;
      stroke-dasharray: 0;
      stroke-dashoffset: 0;
    }
  }

  &--large {
    width: 28px;
    height: 28px;
  }

  &--absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 0;
    margin: 0;
    transform: translate(-50%, -50%);
  }

  &--faded {
    opacity: 0.4;
  }
}