$block : 'slider';

.#{$block} {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: #efefef;
  outline: none;
  transition: background-color .2s;
  border-radius: 2px;
  cursor: pointer;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    background: indigo;
    cursor: pointer;
    border-radius: 50%;
  }

  &::-moz-range-thumb {
    width: 16px;
    height: 16px;
    background: #efefef;
    cursor: pointer;
    border-radius: 50%;
  }

  &:hover {
    background: #e4e4e4;
  }
}
