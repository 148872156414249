$block: 'filter';

.#{$block} {
  display: inline-block;
  padding: 0 15px;
  margin: 5px;
  line-height: 26px;
  font-size: 18px;
  color: indigo;
  border-radius: 15px;
  border: 2px solid #ccc;
  cursor: pointer;
  background: transparent;

  &:hover {
    border-color: indigo;
  }

  &.is-active {
    background-color: indigo;
    border-color: indigo;
    color: white;
  }

  &--null {
    border-color: transparent;
    color: #ccc;
    padding: 0;
    float: right;

    &:hover {
      border-color: transparent;
      color: indigo;
    }
  }

  &--small {
    
  }

}
