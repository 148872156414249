$block : 'fab';

.#{$block} {

  &__panel {
    z-index: 99;
    position: fixed;
    top: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    opacity: 0;
    padding-top: 40px;
    transition: opacity 150ms ease-in-out,
    top 150ms ease-in-out;
    overflow: scroll;
    pointer-events: none;

    &.is-active {
      pointer-events: auto;
      top: 0;
      opacity: 1;
    }

    // @media screen and (min-width: 1200px) {

    //   left: 50%;
    //   right: auto;
    //   width: 600px;
    //   transform: translate(-50%, 0);

    //   &.is-active {
    //     top: 80px;
    //     bottom: auto;
    //     box-shadow: 0 4px 8px 1px rgba(indigo, 0.2);
    //     border-radius: 4px;
    //   }

    // }


  }

  &__button {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 50px;
    height: 50px;
    background: indigo;
    color: white;
    border-radius: 50%;
    border: 2px solid indigo;
    cursor: pointer;
  }

  &__close {
    display: inline-block;
    top: 10px;
    right: 10px;
    position: absolute;
    background: transparent;
    color: indigo;
    border: none;
    cursor: pointer;
  }
}
