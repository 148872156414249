$block : 'form';

.#{$block} {

  &__row {
    display: block;
    position: relative;
    margin: 16px 0;
    width: 100%;
    padding: 0 10px;
  }
}