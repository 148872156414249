$block: 'text-message';

.#{$block} {
  text-align: center;
  font-size: 20px;
  color: grey;
  line-height: 25px;
  margin: 20px 0;

  &__sub {
    font-size: 16px;
  }
}