.avatar {
  flex: 0 0 40px;
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #9dff75;
  color: #9dff75;

  &__name {
    display: block;
    color: #9dff75;
    margin: 0 10px;
  }
}