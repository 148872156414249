$block: 'caret';

.#{$block} {
  width: 1rem;
  height: 1rem;
  stroke: #ccc;
  vertical-align: text-bottom;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: miter;
  fill: none;
  color: #ccc;
  transition: transform 200ms ease-in-out;
  align-self: center;

  &--opened {
      transform: rotate(180deg);
  }
}