$block: 'toggle';

.#{$block} {
  display: none;

  &__label {
    display: block;
    padding-left: 50px;
    line-height: 34px;
    font-weight: bold;
    cursor: pointer;
    font-size: 18px;

    &:hover {
      color: indigo;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 6px;
      left: 10px;
      width: 40px;
      height: 24px;
      border-radius: 12px;
      background: #e3e3e3;
    }

    &:after {
      content: '';
      position: absolute;
      left: 14px;
      top: 10px;
      border: 2px solid indigo;
      width: 12px;
      height: 12px;
      background: indigo;
      border-radius: 50%;
      transition: left 200ms ease-in-out;
    }

  }

  &:checked + .#{$block}__label {

    &:after {
      left:29px;
    }

  }

}