$block: 'checkbox';

.#{$block} {
  display: none;

  &__checkmark {
    position: absolute;
    top: 2px;
    left: 12px;
    color: indigo;
    opacity: 0;
  }

  &__label {
    display: block;
    padding-left: 30px;
    line-height: 20px;
    font-weight: bold;
    cursor: pointer;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 10px;
      border: 2px solid white;
      width: 16px;
      height: 16px;
      border-radius: 2px;
    }

    &:after {
      content: '';
      display: none;
      position: absolute;
      top: 0;
      left: 10px;
      border: 2px solid white;
      width: 16px;
      height: 16px;
      background: white;
      border-radius: 2px;
    }


  }

  &:checked + .#{$block}__label {

    &:after {
      display: block;
    }
  }

  &:checked + .#{$block}__label + .#{$block}__checkmark {

    display: block;
    color: indigo;
    opacity: 1;
  }

}